<template>
    <div class="bg">
        <div class="user-box">
            <img :src="rImg(data.head_portrait)" :onerror="txImg" class="tx" alt="" />
            <div class="user">
                <h3 class="name">{{ data.name }}</h3>
                <p class="u-id phone" @click="copyTxt(data.id)">热浪号：{{data.id}} <i class="el-icon-document-copy"></i></p>
                <div class="num-box">
                    <span class="num"><span class="n" v-html="returnNum(data.friend)"></span>好友</span>
                    <span class="num"><span class="n" v-html="returnNum(data.following)"></span>关注</span>
                    <span class="num" style="margin-right:0"><span class="n" v-html="returnNum(data.attention)"></span>粉丝</span>
                    <span class="num u-id pc" @click="copyTxt(data.id)">热浪号：{{data.id}} <i class="el-icon-document-copy"></i></span>
                </div>
            </div>
        </div>
        <div class="flex-c">
            <a href="#/app_admin" style="width:80%">
                <el-button slot="title" type="primary" style="width:100%">作品管理</el-button>
            </a>
        </div>
        <ul class="data-box">
            <!-- <li class="list" v-if="rInfo.id">
                <div class="flex-bt">
                    <p class="num" style="font-size: 18px;font-weight: bold;">{{rInfo.name}}</p>
                    <el-button slot="title" v-if="sex=='女'" @click="joinRank()" :type="rInfo.joined?'info':'primary'" size="mini">{{rInfo.joined? '已报名':'报名参加'}}</el-button>
                    <a href="#/ranklist" v-else>
                        <el-button slot="title" type="primary" size="mini">前往点评</el-button>
                    </a>
                </div>
                <div class="title discount" style="margin-top: 10px;">
                    <p class="discount-p">报名时间：<span>{{rInfo.enroll_time}}</span></p>
                    <p class="discount-p">评选时间：<span>{{rInfo.start_time}}</span></p>
                    <p class="discount-p">结束时间：<span>{{rInfo.end_time}}</span></p>
                    <p class="discount-p">活动说明：<span>仅限女士报名，每期每位男士只能给每位参赛选手评分一次。<a href="#/rankRule"><span class="tip">更多请查看详情</span></a></span></p>
                    <p class="discount-p" v-if="sex=='女' && rInfo.joined && rMyInfo.id">本期{{rMyInfo.index?'排名/':''}}分数/点评人数：<span class="tip">{{rMyInfo.index?rMyInfo.index+'/':''}}{{rMyInfo.score_avg}}/{{rMyInfo.scoring_count}}</span></p>
                    <p class="discount-p reason" v-if="rMyInfo.state == -1">参赛情况：资格已被取消<span v-if="rMyInfo.reason">{{rMyInfo.reason}}</span></p>
                </div>
            </li> -->
            <li class="list" v-if="discountInfo.name">
                <div class="flex-bt">
                    <p class="num" style="font-size: 18px;font-weight: bold;">{{discountInfo.name}}</p>
                    <el-button slot="title" @click="joinDisCount()" :type="discountInfo.joined?'info':'primary'" size="mini">{{discountInfo.joined? '退出活动':'报名参加'}}</el-button>
                </div>
                <div class="title discount" style="margin-top: 10px;">
                    <p class="discount-p">开始时间：<span>{{discountInfo.starttime}}</span></p>
                    <p class="discount-p">结束时间：<span>{{discountInfo.endtime}}</span></p>
                    <p class="discount-p">活动说明：<span>{{discountInfo.description}}</span></p>
                    <p class="discount-p">活动折扣：<span>{{discountInfo.discount}}%</span></p>
                </div>
            </li>
            <li class="list">
                <p class="num">{{artInfo.view_num}}</p>
                <p class="title"><i class="el-icon-view"></i> 观看</p>
            </li>
            <li class="list">
                <p class="num">{{artInfo.video_time}}</p>
                <p class="title"><i class="el-icon-time"></i> 观看总时长</p>
            </li>
            <li class="list">
                <p class="num">{{artInfo.thumbsup}}</p>
                <p class="title"><i class="el-icon-thumb"></i> 点赞</p>
            </li>
            <li class="list">
                <p class="num">{{artInfo.collect}}</p>
                <p class="title"><i class="new-sc"></i> GET</p>
            </li>
            <li class="list">
                <p class="num">{{artInfo.comment}}</p>
                <p class="title"><i class="el-icon-chat-round"></i> 评论</p>
            </li>
        </ul>
    </div>
</template>

<script>
    import {
        mapGetters,
        mapMutations
    } from 'vuex';
    export default {
        components: {},
        data() {
            return {
                data: {},
                artInfo: {},
                discountInfo: {},
                sex: '',
                rInfo: {}, // 选美配置
                rMyInfo: {}, // 我的排名情况
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。

        },
        mounted() {
            // 在组件被挂载之后调用。
            this.getData();
            this.getArtData();
            this.getDiscount();
            // this.getRankInfo();
        },
        methods: {
            ...mapMutations(["s_setUser"]),
            // 获取并设置个人资料
            async getData() {
                let _this = this;
                let t = this.$route.query.t;
                if (t) {
                    let param = t.split('.')[1];
                    if (param && atob(param)) {
                        param = JSON.parse(atob(param));
                        param = param.data;
                        this.$y_setKey("token", t);
                        this.$y_setKey("userid", param.userid);
                        this.$y_setKey("sex", param.sex);
                        this.sex = param.sex;
                        const info = await this.$y_getUser();
                        if (info) {
                            this.data = info;
                            this.s_setUser(info);
                        }
                    }
                } else {
                    if (!this.$y_getKey('token')) {
                        this._home();
                    } else {
                        this.data = this.$y_getKey('userInfo');
                        this.sex = this.data.sex;
                    }
                }
            },
            // 获取文章数据
            async getArtData() {
                let {
                    code,
                    data
                } = await this.$y_ajax("api/nihao/data");
                if (code == 200) {
                    this.artInfo = data;
                }
            },
            copyTxt(txt) {
                navigator.clipboard.writeText(txt).then(() => {
                    this.$y_msg("复制成功");
                });
            },
            // 打折活动
            async getDiscount() {
                let {
                    code,
                    data
                } = await this.$y_ajax("api/v2.Activity/chatDiscountConfig");
                if (code == 200) {
                    const t = new Date().getTime();
                    let at = data.endtime.replace(/-/ig, '/');
                    at = new Date(at).getTime();
                    if (t <= at) {
                        this.discountInfo = data;
                    }

                    // this.$y_msg("复制成功");
                }
            },
            // 参加活动
            async joinDisCount() {
                let url = this.discountInfo.joined ? 'api/v2.activity/chatDiscountExit' : 'api/v2.Activity/chatDiscountJoin'
                let {
                    code,
                    msg
                } = await this.$y_ajax(url);
                this.$y_msg(msg);
                if (code == 200) {
                    this.discountInfo.joined = this.discountInfo.joined ? false : true;
                }
            },
            // 获取选美大赛配置
            async getRankInfo() {
                let {
                    code,
                    data
                } = await this.$y_ajax("api/v2.pageants/current");
                if (code == 200) {
                    this.rInfo = data;
                    this.getMyRank()
                }
            },
            // 获取自己的排名情况
            async getMyRank() {
                let {
                    code,
                    data
                } = await this.$y_ajax("api/v2.pageants/info", {
                    id: this.rInfo.id,
                    user_id: this.$y_getKey('userid')
                });
                if (code == 200) {
                    this.rMyInfo = data;
                }
            },
            // 参加选美
            async joinRank() {
                let {
                    code,
                    msg
                } = await this.$y_ajax("api/v2.pageants/enroll");
                if (code == 200) {
                    this.rInfo.joined = true;
                } else {
                    this.$y_msg(msg)
                }
            },


        },
        computed: {
            // 计算属性
            // ...mapGetters([])
        },
        watch: {
            // 监听
        }
    };
</script>

<style lang="less" scoped>
    .user-box {
        background: #fff;
        border-radius: 10px;
        padding: 20px;
        display: flex;
        align-items: center;

        .tx {
            width: 80px;
            height: 80px;
            margin-right: 20px;
        }

        .user {
            flex: 1;
        }

        .name {
            font-size: 25px;
            font-weight: normal;
        }

        .num-box {
            margin-top: 10px;
            color: #aaa;

            .num {
                margin-right: 30px;

                .n {
                    color: #333;
                    margin-right: 5px;
                    font-size: 18px;
                }
            }

            .u-id {
                border-left: 1px solid #eee;
                padding-left: 30px;
                margin-left: 30px;
            }
        }

        .u-id {
            color: #aaa;
            font-size: 0.6rem;
        }
    }

    .data-box {
        background: #fff;
        border-radius: 10px;
        padding: 20px;
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;

        .list {
            border: 1px solid #eee;
            border-radius: 10px;
            width: 30%;
            padding: 30px;
            box-sizing: border-box;
            margin-right: 5%;
            margin-bottom: 20px;

            &:nth-child(3n) {
                margin-right: 0;
            }

            .title {
                color: #999;
            }

            .num {
                font-size: 35px;
            }
        }
    }

    .pc {
        display: inline-block;
    }

    .phone {
        display: none;
    }

    /* 移动端 */
    @media (max-width: 600px) {
        .data-box {
            .list {
                width: 100%;
                margin-right: 0;
            }
        }

        .pc {
            display: none;
        }

        .phone {
            display: inline-block;
        }
    }

    .discount-p {
        margin-top: 10px;

        span {
            color: #555;
        }

        .tip {
            color: #fd5621;
        }
    }
</style>